import {
  faBars,
  faChartGantt,
  faChartLine,
  faChartPie,
  faSun,
  faTableCells,
  faTableCellsLarge,
  faBarsProgress,
  faTableList,
  faTimeline,
  faChartBar,
  faChartSimple,
  faChartColumn,
  faMagnifyingGlassChart
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Table } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import logo from "../../../assets/miLogo.png";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Sidebar.module.scss";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../../../Redux/Action/filter.action";
import TooltipButton from "../../TooltipButton/TooltipButton";
import AxiosService from "../../../utils/AxiosService";
import { useEffect } from "react";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const activeTab = location.pathname;
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const apiService = new AxiosService();
  const [modules, setModules] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const options = [
    {
      icon: faTableCells,
      title: "Cumulative Analytics",
      tooltip: "Cumulative Analytics",
      url: "/cumulative-analytics",
      module: "cumulativeAnalytics",
    },
    {
      icon: faChartPie,
      title: "Insights by Access Type",
      tooltip: "Insights by Access Type",
      url: "/insights-by-access-type",
      module: "insightsByAccessType",
    },
    {
      icon: faSun,
      title: "Insights by Publishers",
      tooltip: "Insights by Publishers",
      url: "/insights-by-publishers",
      module: "insightsByPublishers",
    },
    {
      icon: faChartGantt,
      title: "Insights by Rejecting Journals",
      tooltip: "Insights by Rejecting Journals",
      url: "/insights-by-rejecting-journals",
      module: "insightsByRejectingJournals",
    },
    {
      icon: faBarsProgress,
      title: "Insights by Accepting Journals",
      tooltip: "Insights by Accepting Journals",
      url: "/insights-by-accepting-journals",
      module: "insightsByAcceptingJournals",
    },
    {
      icon: faChartBar,
      title: "Insights by Subject Category and Respective Citations",
      tooltip: "Insights by Subject Category and Respective Citations",
      url: "/insights-by-subject-category-and-respective-citations",
      module: "insightsBySubjectCategoryAndRespectiveCitations",
    },
    {
      icon: faTableList,
      title: `Insights by Accepting Journals vs. Subject Categories`,
      tooltip: `Insights by Accepting Journals vs. Subject Categories`,
      url: "/insights-by-accepting-journals-vs-subject-categories",
      module: "insightsByAcceptingJournalsVsSubjectCategories",
    },
    {
      icon: faTableCellsLarge,
      title: `Insights by Rejecting vs. Accepting <br/> Journals`,
      tooltip: `Insights by Rejecting vs. Accepting Journals`,
      url: "/insights-by-rejecting-vs-accepting-journals",
      module: "insightsByRejectingVsAcceptingJournals",
    },
    {
      icon: faChartLine,
      title: "Insights by Time to Publication",
      tooltip: "Insights by Time to Publication",
      url: "/insights-by-time-to-publication",
      module: "insightsByTimeToPublication",
    },
    {
      icon: faTimeline,
      title: "Insights by Average Citations",
      tooltip: "Insights by Average Citations",
      url: "/insights-by-average-citations",
      module: "insightsByAvgCitations",
    },
    {
      icon: faChartSimple,
      title: "Insights by Rejection and Publication Trend",
      tooltip: "Insights by Rejection and Publication Trend",
      url: "/insights-by-rejection-and-Publication-Trend",
      module: "insightsByRejectionAndPublicationTrend",
    },
    {
      icon: faChartColumn,
      title: "Insights by Journal Cascade",
      tooltip: "Insights by Journal Cascade",
      url: "/insights-by-journal-cascade",
      module: "insightsByJournalCascade",
    },
    {
      icon: faMagnifyingGlassChart,
      title: "Advanced Analytics",
      tooltip: "Advanced Analytics",
      url: "/advanced-analytics",
      module: "advancedAnalytics",
    },
  ];

  function handleChangePage(option) {
    ReactGA.event({
      category: option.title,
      action: "Hamburger Menu Clicked",
    });

    dispatch(setCurrentPage(1));
    handleClose();
    navigate(option.url);
  }

  function getHamburgerMenu() {
    apiService
      .fetchHamburgetMenu()
      .then((res) => {
        setModules(res?.data?.result?.configs);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }

  useEffect(() => {
    getHamburgerMenu();
  }, []);

  return (
    <div>
      <div>
        {show ? (
          <>
            <div className="ps-3">
              <FontAwesomeIcon
                icon={faBars}
                onClick={handleShow}
                size="xl"
                className="cursor-pointer"
              />
            </div>
            <Offcanvas
              show={show}
              onHide={handleClose}
              className={styles.sidebarStyle}
            >
              <Offcanvas.Header>
                <Offcanvas.Title>
                  <span className="ps-2 pe-3">
                    <FontAwesomeIcon
                      icon={faBars}
                      onClick={handleClose}
                      size="lg"
                      className="cursor-pointer"
                    />
                  </span>
                  <img width="145" src={logo} alt="logo" />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="p-0">
                <Table className={classNames(styles.menuStyle, "")}>
                  <tr className={styles.menuTitle}>
                    <td className="ps-3"></td>
                    <td className="pe-3">Analytics</td>
                  </tr>
                  {options?.map((obj) => (
                    <>
                      {obj.module === "cumulativeAnalytics" && (
                        <tr
                          key={obj.title}
                          onClick={() => handleChangePage(obj)}
                          className={
                            activeTab === obj.url
                              ? styles.active
                              : styles.listItem
                          }
                        >
                          <td className="ps-3">
                            <FontAwesomeIcon icon={obj.icon} />{" "}
                          </td>
                          <td className="pe-3">
                            <div
                              dangerouslySetInnerHTML={{ __html: obj.title }}
                            />
                          </td>
                        </tr>
                      )}
                      {modules?.map(
                        (m) =>
                          obj.module === m && (
                            <tr
                              key={obj.title}
                              onClick={() => handleChangePage(obj)}
                              className={
                                activeTab === obj.url
                                  ? styles.active
                                  : styles.listItem
                              }
                            >
                              <td className="ps-3">
                                <FontAwesomeIcon icon={obj.icon} />{" "}
                              </td>
                              <td className="pe-3">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: obj.title,
                                  }}
                                />
                              </td>
                            </tr>
                          )
                      )}
                    </>
                  ))}
                </Table>
              </Offcanvas.Body>
            </Offcanvas>
          </>
        ) : (
          <div className={classNames(styles.sidebarStyleMin, "border-end")}>
            <div className={classNames(styles.menuStyle, "")}>
              <div className={styles.mainHeadingMin}>
                <FontAwesomeIcon
                  icon={faBars}
                  onClick={handleShow}
                  size="xl"
                  className="cursor-pointer"
                />
              </div>

              {options?.map((obj) => (
                <>
                  {obj.module === "cumulativeAnalytics" && (
                    <TooltipButton
                      type="string"
                      placement="right"
                      tooltipMessage={obj.tooltip}
                    >
                      <div
                        key={obj.title}
                        onClick={() => handleChangePage(obj)}
                        className={
                          activeTab === obj.url
                            ? styles.activeMin
                            : styles.listItemMin
                        }
                      >
                        <FontAwesomeIcon icon={obj.icon} />
                      </div>{" "}
                    </TooltipButton>
                  )}
                  {modules?.map(
                    (m) =>
                      obj.module === m && (
                        <TooltipButton
                          key={m}
                          type="string"
                          placement="right"
                          tooltipMessage={obj.tooltip}
                        >
                          <div
                            key={obj.title}
                            onClick={() => handleChangePage(obj)}
                            className={
                              activeTab === obj.url
                                ? styles.activeMin
                                : styles.listItemMin
                            }
                          >
                            <FontAwesomeIcon icon={obj.icon} />
                          </div>{" "}
                        </TooltipButton>
                      )
                  )}
                </>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Sidebar;
