import { useState, useEffect, useRef } from "react";
import { Card, Row, Col, CardBody, Dropdown } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faArrowLeft,
  faBars,
  faExpand,
  faCompress,
} from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../components/Pagination/Pagination";
import AxiosService from "../../utils/AxiosService";
import { SpinnerLoader } from "../../components/Loader/Loader";
import PopupModule from "../../components/Tableau/PopupModule/PopupModule";
import TableLoader from "../../components/Table/TableLoader";
import "./AdvancedAnalytics.scss";

import {
  TableauViz,
  TableauEventType,
  TableauDialogType,
} from "https://public.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js";
// import { count } from "d3";

const { tableau } = window;

const AdvancedAnalytics = () => {
  const [vizUrl, setVizUrl] = useState(null);
  const [currentReportName, setCurrentReportName] = useState(null);
  const [viz, setViz] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [token, setToken] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [data, setData] = useState([]);
  const [tableauError, setTableauError] = useState(false);
  const [showTableauCustomBtn, setShowTableauCustomBtn] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isGraphLoading, setIsGraphLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showTaleauPopUPTable, setShowTaleauPopUPTable] = useState(false);
  const [isReportTableLoading, setIsReportTableLoading] = useState(false);
  const [showViewData, setShowViewData] = useState(false);
  const [tableauReportData, setTableauReportData] = useState({
    header: [],
    data: [],
  });
  const [chartBreadcrumb, setChartBreadcrumb] = useState([]);
  const [tableauFilterEvent, setTableauFilterEvent] = useState({});
  const [markSelectionChangedEvent, setMarkSelectionChangedEvent] = useState(
    {}
  );
  const [isFilterReset, setIsFilterReset] = useState(false);

  // const dispatch = useDispatch();
  const axiosService = new AxiosService();

  const offset = 10;
  let chartBreadcrumbArray = [];
  // const chartBreadcrumbObject = {};
  const [chartBreadcrumbObject, setChartBreadcrumbObject] = useState({});
  let hasFilterChange = false;
  let filterTimeOut;

  const tableHeaders = ["Report Name", "Report Link"];

  const navigate = useNavigate();

  const tableauViz = document.querySelector("tableau-viz");

  // useEffect(() => {
  //   tableauViz?.addEventListener(TableauEventType.FilterChanged, (e) => {
  //     console.log("use effect filter...");
  //   });

  //   // tableauViz?.addEventListener("click", () => {
  //   //   console.log("use effect click...");
  //   // });
  // }, []);

  // tableauViz?.addEventListener("click", () => {
  //   console.log("use effect click...");
  // });

  // let count = 0;
  // const markSelectionChanged = () => {
  //   count = count + 1;
  //   console.log(" before MarkSelectionChanged...	", count);
  //   if (count == 1) {
  //     count = 0;
  //     console.log("MarkSelectionChanged...	", count);
  //   }
  // };
  let filteredItem;

  // useEffect(() => {
  //   console.log(
  //     "use effect =>>>>>>>>",
  //     chartBreadcrumb,
  //     chartBreadcrumbObject,
  //     filteredItem
  //   );
  //   // if (
  //   //   !Object.values(chartBreadcrumbObject).includes(filteredItem) &&
  //   //   filteredItem
  //   // ) {
  //   //   setChartBreadcrumbObject((Prev) => ({
  //   //     ...Prev,
  //   //     [filteredItem]: Object.keys(chartBreadcrumbObject).length,
  //   //   }));
  //   // }

  //   if (chartBreadcrumb?.length) {
  //     chartBreadcrumb.forEach((item, index) => {
  //       const trimedItem = item.split(":")[0].trim();
  //       if (!Object.values(chartBreadcrumbObject).includes(trimedItem)) {
  //         setChartBreadcrumbObject((Prev) => ({
  //           ...Prev,
  //           [chartBreadcrumb.length - 1]: item,
  //           // [trimedItem]: chartBreadcrumb.length, //Object.keys(chartBreadcrumbObject).length,
  //         }));
  //       }
  //       // else if (
  //       //   Object.values(chartBreadcrumbObject).includes(trimedItem) &&
  //       //   !Object.values(chartBreadcrumbObject).includes(item)
  //       // ) {
  //       //   setChartBreadcrumbObject((Prev) => ({
  //       //     ...Prev,
  //       //     [Object.values(chartBreadcrumbObject).includes(trimedItem)]: item,
  //       //     // [trimedItem]: chartBreadcrumb.length, //Object.keys(chartBreadcrumbObject).length,
  //       //   }));
  //       // }
  //     });
  //   } else {
  //     setChartBreadcrumbObject({});
  //   }

  //   // if (chartBreadcrumb.length !== Object.keys(chartBreadcrumbObject).length) {
  //   //   const tempBreadcrumSequenceObject = {};
  //   //   Object.keys(chartBreadcrumbObject).forEach((item, index) => {
  //   //     if (chartBreadcrumb.includes(item)) {
  //   //       tempBreadcrumSequenceObject[item] = index;
  //   //     }
  //   //   });
  //   //   setChartBreadcrumbObject(tempBreadcrumSequenceObject);
  //   // }
  //   // else{}
  // }, [chartBreadcrumb]);

  const filterChangedHandler = async (event) => {
    filteredItem = event.detail.fieldName;
    // .replace("Action (", "")
    // .replace(")", "");

    // console.log("filteredItem => ", filteredItem);

    const sheetFilters =
      await tableauViz.workbook.activeSheet.worksheets[0].getFiltersAsync();

    if (sheetFilters) {
      // console.log("filter is running => ", sheetFilters);
      chartBreadcrumbArray = [...chartBreadcrumb];

      sheetFilters.forEach((item, index) => {
        if (
          item.fieldName === filteredItem &&
          !item.isAllSelected
          // !chartBreadcrumb.includes(filteredItem)
        ) {
          if (index) {
            // console.log(
            //   "adding element to array...",
            //   filteredItem.replace("Action (", "").replace(")", ""),
            //   chartBreadcrumbArray,
            //   chartBreadcrumbArray.some((item) =>
            //     item.includes(
            //       filteredItem.replace("Action (", "").replace(")", "").trim()
            //     )
            //   )
            // );
            if (
              !chartBreadcrumbArray.some((item) =>
                item.includes(
                  filteredItem.replace("Action (", "").replace(")", "").trim()
                )
              )
            ) {
              chartBreadcrumbArray.push(
                item.fieldName.replace("Action (", "").replace(")", "") +
                  " : " +
                  item.appliedValues[0].formattedValue
              );
            } else {
              // console.log(
              //   "item is already in the array",
              //   item.appliedValues[0].formattedValue
              // );

              chartBreadcrumbArray.forEach((e, i) => {
                if (
                  e.includes(
                    filteredItem.replace("Action (", "").replace(")", "").trim()
                  )
                ) {
                  chartBreadcrumbArray[i] =
                    filteredItem
                      .replace("Action (", "")
                      .replace(")", "")
                      .trim() +
                    " : " +
                    item.appliedValues[0].formattedValue;
                }
              });

              // console.log("chartBreadcrumbArray => ", chartBreadcrumbArray);
            }
            // setChartBreadcrumb((prev) => [...prev, filteredItem]);
          }
          // chartBreadcrumbArray.push(filteredItem);
          // setChartBreadcrumb(chartBreadcrumbArray);
        } else if (
          item.fieldName === filteredItem &&
          item.isAllSelected //&&
          // chartBreadcrumb.includes(filteredItem)
        ) {
          // console.log("removing element from array...");
          // chartBreadcrumbArray = chartBreadcrumbArray.filter(
          //   (arrItem) => arrItem != filteredItem
          // );
          // setChartBreadcrumb(
          //   chartBreadcrumb.filter((arrItem) => arrItem != filteredItem)
          // );

          chartBreadcrumbArray = chartBreadcrumbArray.filter(
            (item) =>
              !item.includes(
                filteredItem.replace("Action (", "").replace(")", "")
              )
          );
        }
      });

      setChartBreadcrumb(chartBreadcrumbArray);
      // console.log("chartBreadcrumbArray... ", chartBreadcrumbArray);
    }
    // }
  };

  const filterResetHandler = async (markSelectionChangedEvent) => {
    const markSelectionReset =
      await markSelectionChangedEvent?.detail?.sheet?.getFiltersAsync();

    if (markSelectionReset?.some((item) => item.worksheetName === "Reset")) {
      // setIsFilterReset(true);
      setChartBreadcrumb([]);
      // console.log("isFilterReset =>");
    }
  };

  // useEffect(() => {

  // }, []);

  // tableauViz?.addEventListener("click", () => {
  //   console.log("document clicked...");
  // });
  useEffect(() => {
    if (tableauFilterEvent?.detail?.fieldName) {
      // console.log(
      //   "tableauFilterEvent =>",
      //   tableauFilterEvent?.detail?.fieldName
      // );
      filterChangedHandler(tableauFilterEvent);
    }
  }, [tableauFilterEvent]);

  useEffect(() => {
    // const markSelectionReset =
    //   await markSelectionChangedEvent?.detail?.sheet?.getFiltersAsync();
    // if (isFilterReset) {
    //   console.log("isFilterReset =>", isFilterReset);
    //   setChartBreadcrumb([]);
    // }
    // console.log("isFilterReset =>", markSelectionReset);
    if (markSelectionChangedEvent) {
      filterResetHandler(markSelectionChangedEvent);
    }
    // if (markSelectionReset?.some((item) => item.worksheetName === "Reset")) {
    //   // setIsFilterReset(true);
    //   // setChartBreadcrumb([]);
    //   // console.log("isFilterReset =>");
    // }
  }, [markSelectionChangedEvent]);

  if (tableauViz) {
    tableauViz.addEventListener(
      TableauEventType.FirstInteractive,
      async (e) => {
        checkReport(tableauViz);
        setShowTableauCustomBtn(true);
        // getAllTableData(tableauViz);
        // console.log("FirstInteractive	");

        // console.log("worksheet => ", await tableauViz.workbook.activeSheet);
      }
    );

    tableauViz.addEventListener(
      TableauEventType.MarkSelectionChanged,
      async (e) => {
        // console.log("MarkSelectionChanged...", e);
        setMarkSelectionChangedEvent(e);
        // const markSelectionReset = await e.detail.sheet.getFiltersAsync();

        // // console.log(
        // //   "MarkSelectionChanged worksheet =>",
        // //   await e.detail.sheet.getFiltersAsync()
        // // );
        // if (markSelectionReset.some((item) => item.worksheetName === "Reset")) {
        //   setIsFilterReset(true);
        // }
      }
    );

    tableauViz?.addEventListener(TableauEventType.FilterChanged, async (e) => {
      // count = count + 1;
      // console.log("filter event =>", e.detail.fieldName);
      // console.log(
      //   "await tableauViz.workbook.activeSheet.worksheets =>",
      //   await tableauViz.workbook
      // );

      setTableauFilterEvent(e);
      // filterChangedHandler(e);
    });

    // let debounceTimeout;

    // tableauViz.addEventListener(TableauEventType.MarkSelectionChanged, () => {
    //   clearTimeout(debounceTimeout);
    //   debounceTimeout = setTimeout(() => {
    //     console.log("MarkSelectionChanged...");
    //   }, 200); // 200 ms debounce time
    // });

    // tableauViz.addEventListener(TableauEventType.FilterChanged, async (e) => {
    //   let filteredItem = e.detail.fieldName;
    //   // chartBreadcrumbArray = [];

    //   clearTimeout(filterTimeOut);
    //   filterTimeOut = setTimeout(async () => {
    //     console.log("Filter filterTimeOut:");
    //     const sheetFilters =
    //       await tableauViz.workbook.activeSheet.worksheets[0].getFiltersAsync();

    //     if (sheetFilters) {
    //       // console.log("filter is running");
    //       chartBreadcrumbArray = [];

    //       sheetFilters.forEach((item, index) => {
    //         if (
    //           // item.fieldName === filteredItem &&
    //           !item.isAllSelected
    //           // !chartBreadcrumbArray.includes(filteredItem)
    //         ) {
    //           if (index) {
    //             chartBreadcrumbArray.push(item.fieldName);
    //           }
    //           // chartBreadcrumbArray.push(filteredItem);
    //           // setChartBreadcrumb(chartBreadcrumbArray);
    //         }
    //       });

    //       // setChartBreadcrumb(chartBreadcrumbArray);
    //     }
    //   }, 200); // Adjust the debounce delay as needed
    //   // console.log("chartBreadcrumbArray =>", chartBreadcrumbArray);
    //   setChartBreadcrumb(chartBreadcrumbArray);

    //   // if (!chartBreadcrumbArray.includes(filteredItem)) {
    //   //   chartBreadcrumbArray.push(filteredItem);
    //   //   // setChartBreadcrumb(chartBreadcrumbArray);
    //   // }
    //   // console.log(
    //   //   "tableauViz.workbook.activeSheet.worksheets",
    //   //   tableauViz.workbook.activeSheet.worksheets
    //   // );
    //   // //GET FILTER VALUE
    //   // const sheetFilters =
    //   //   await tableauViz.workbook.activeSheet.worksheets[0].getFiltersAsync();
    //   // // console.log(sheetFilters);

    //   // if (sheetFilters) {
    //   //   console.log("filter is running");

    //   //   chartBreadcrumbArray = [];
    //   //   sheetFilters.forEach((item, index) => {
    //   //     if (
    //   //       // item.fieldName === filteredItem &&
    //   //       !item.isAllSelected
    //   //       // !chartBreadcrumbArray.includes(filteredItem)
    //   //     ) {
    //   //       if (index) {
    //   //         chartBreadcrumbArray.push(item.fieldName);
    //   //       }
    //   //       // chartBreadcrumbArray.push(filteredItem);
    //   //       // setChartBreadcrumb(chartBreadcrumbArray);
    //   //     }
    //   //   });

    //   // hasFilterChange = true;

    //   // if (hasFilterChange) {
    //   // setChartBreadcrumb(chartBreadcrumbArray);
    //   // hasFilterChange = false;
    //   // }

    //   // setChartBreadcrumb(chartBreadcrumbArray);

    //   // console.log(
    //   //   "filter applied",
    //   //   // e,
    //   //   // e.detail.fieldName,
    //   //   hasFilterChange,
    //   //   chartBreadcrumbArray,
    //   //   chartBreadcrumb
    //   // );
    //   // }
    // });

    // console.log("after filter");
  }

  // useEffect(() => {
  //   console.log("useeffect is working");
  //   if (hasFilterChange) {
  //     // setChartBreadcrumb(chartBreadcrumbArray);
  //     hasFilterChange = false;
  //   }
  // }, [chartBreadcrumbArray]);

  //CHECKING IF POPUP TABLE WILL BE VISIBLE OR NOT
  const checkReport = async (viz) => {
    setIsReportTableLoading(true);

    // console.log(" check worksheets => ", viz.workbook.activeSheet.worksheets);
    // console.log("check activeSheet => ", viz.workbook.activeSheet);

    let vizActiveSheet = viz.workbook.activeSheet.worksheets.find(
      (item) => item.name == "Tabular Data" //"Accepting Journal vs Country" //"Tabular Data"
    );
    if (!vizActiveSheet) {
      vizActiveSheet = viz.workbook.activeSheet.worksheets.find(
        (item) => item.name == "KPI's" //"Accepting Journal vs Country" //"Accepting Journal vs Country" //"Tabular Data"
      );
    }

    if (vizActiveSheet?.sheetType == "worksheet") {
      const tables = await vizActiveSheet.getUnderlyingTablesAsync();

      // console.log("check tables => ", tables);
      const options = {
        maxRows: 0, // Max rows to return. Use 0 to return all rows.
        ignoreAliases: false,
        ignoreSelection: true,
        includeAllColumns: false,
      };

      if (tables[0]?.caption == "Report") {
        setShowViewData(true);
      } else {
        setShowViewData(false);
      }
    }
  };

  //CALLING POPUP TABLE DATA
  const getAllTableData = async (viz) => {
    setIsReportTableLoading(true);
    // console.log("worksheets => ", viz.workbook.activeSheet.worksheets);
    // console.log("activeSheet => ", viz.workbook.activeSheet);

    let vizActiveSheet = viz.workbook.activeSheet.worksheets.find(
      (item) => item.name == "Tabular Data" //"Accepting Journal vs Country" //"Tabular Data"
    );
    if (!vizActiveSheet) {
      vizActiveSheet = viz.workbook.activeSheet.worksheets.find(
        (item) => item.name == "KPI's" //"Accepting Journal vs Country" //"Accepting Journal vs Country" //"Tabular Data"
      );
    }

    if (vizActiveSheet?.sheetType == "worksheet") {
      const tables = await vizActiveSheet.getUnderlyingTablesAsync();
      const options = {
        maxRows: 0, // Max rows to return. Use 0 to return all rows.
        ignoreAliases: false,
        ignoreSelection: true,
        includeAllColumns: false,
      };

      // console.log("tables => ", tables);
      if (tables[0]?.caption == "Report") {
        const underlyingTableData =
          await vizActiveSheet.getUnderlyingTableDataAsync(
            tables[0].id,
            options
          );

        setTableauReportData({
          header: underlyingTableData.columns,
          data: underlyingTableData.data,
        });
        setIsReportTableLoading(false);

        // console.log("underlyingTableData => ", underlyingTableData);
      } else {
        // console.log("No Report available");
        setIsReportTableLoading(false);
        setTableauReportData({
          header: [],
          data: [],
        });
      }
    } else {
      // console.log("No worksheet available");
      setIsReportTableLoading(false);
      setTableauReportData({
        header: [],
        data: [],
      });
    }
  };

  const initViz = () => {
    setTableauError(false);
    setShowTableauCustomBtn(false);

    if (viz) {
      // Dispose of the previous viz if it exists
      viz.dispose();
    }

    let vizElement;

    try {
      const vizContainer = document.getElementById("vizContainer");

      if (vizUrl && token && vizContainer) {
        vizElement = new tableau.Viz(vizContainer, vizUrl, {
          width: "100%",
          height: "100vh",
          toolbar: "button",
          // hideEditButton: true,
          // hideTabs: "true",
          // hideEditButton: "false",
          auth: {
            token: token,
          },
        });

        setViz(vizElement);
      }
    } catch (error) {
      console.error("Error", error);
      setTableauError(true);
      setVizUrl("error");
    }
    // setViz(newViz);
  };

  const downloadImage = async (downloadFormate) => {
    switch (downloadFormate) {
      case "pdf":
        await tableauViz.displayDialogAsync(TableauDialogType.ExportPDF);
        break;
      case "image":
        await tableauViz.exportImageAsync();
        break;
      case "ppt":
        const activeSheet = tableauViz.workbook.activeSheet;
        const worksheets = activeSheet.worksheets;
        const selectedWorksheetsForExport = Array.from(
          worksheets,
          (worksheet) => worksheet.name
        );

        try {
          await tableauViz
            .exportPowerPointAsync(selectedWorksheetsForExport)
            .then(() => {});
        } catch (e) {
          console.error(e);
        }
        break;

      default:
        break;
    }
  };

  function toggleFullScreen() {
    const vizContainer = document.getElementById("tableau-viz");

    if (!document.fullscreenElement && vizContainer) {
      vizContainer.requestFullscreen();
      // setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        // setIsFullScreen(false);
        document.exitFullscreen();
      }
    }
  }

  const handleTableauPopUpTable = () => {
    setShowTaleauPopUPTable(true);
    getAllTableData(tableauViz);
  };

  function backToLink() {
    navigate("/cumulative-analytics");
  }

  function backToDashboard() {
    setVizUrl(null);
    setCurrentReportName(null);
  }

  const onPageChange = (page) => {
    if (currentPage) {
      setCurrentPage(page);
    }
    // else {
    //   dispatch(setCurrentPage(page));
    // }
  };

  const getAllRecords = async () => {
    setIsTableLoading(true);

    const start = (currentPage - 1) * offset;

    const reqBody = {};

    axiosService
      .getAdvancedAnalyticGrid(start, offset, reqBody)
      .then((response) => {
        if (response?.data) {
          setData(response.data);

          setIsTableLoading(false);
        }
      })
      .catch((error) => {
        setIsTableLoading(false);
        setData([]);
      });
  };

  const handleBreadcrumClick = (event) => {
    const workbookName = event.target.textContent.split(":")[0];
    // console.log("breadcrum event =>", workbookName);

    let sheet = tableauViz.workbook.activeSheet;
    // console.log("sheet =>", sheet, sheet.worksheets);
    const saleMap = sheet.worksheets.find((ws) => {
      // console.log(
      //   "ws.name.includes(workbookName) =>",
      //   ws.name,
      //   ws.name.includes(workbookName.trim()),
      //   ws.name.includes("Country")
      // );
      // return ws.name.includes("KPI's");
      return ws.name.includes("Is_Medline");
    });
    // console.log("saleMap =>", saleMap);
    saleMap.clearFilterAsync("Action (Country)");
  };

  useEffect(() => {
    getAllRecords();
  }, [currentPage]);

  useEffect(() => {
    setShowViewData(false);
    if (vizUrl && vizUrl != "error") {
      setIsGraphLoading(true);
      axiosService
        .getTableauJwtToken()
        .then((response) => {
          if (response?.data) {
            const token = response?.data;
            setToken(token);
            setIsGraphLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error => ", error);
          setIsGraphLoading(false);
        });
    }

    setChartBreadcrumb([]);
  }, [vizUrl]);

  useEffect(() => {
    // if (vizUrl) {
    initViz();
    // }
  }, [token]);

  useEffect(() => {
    document.addEventListener("fullscreenchange", () => {
      if (!document.fullscreenElement) {
        // Fullscreen has been exited
        setIsFullScreen(false);
      } else {
        // Entered fullscreen mode
        setIsFullScreen(true);
      }
    });
  }, []);

  return (
    <>
      <div className=" pt-3" style={{ minHeight: "75vh" }}>
        <PopupModule
          showTaleauPopUPTable={showTaleauPopUPTable}
          setShowTaleauPopUPTable={setShowTaleauPopUPTable}
          handleTableauPopUpTable={handleTableauPopUpTable}
          tableauReportData={tableauReportData}
          setTableauReportData={setTableauReportData}
          isReportTableLoading={isReportTableLoading}
        />
        <Row>
          {/* xl={6} */}
          <Col>
            <h5 className="">
              {
                <>
                  <span
                    className="linkText cursor-pointer noUnderline"
                    onClick={backToLink}
                  >
                    Cumulative Analytics{" "}
                  </span>
                  {">"}
                </>
              }
              {
                <span
                  className={
                    currentReportName
                      ? "linkText cursor-pointer noUnderline"
                      : ""
                  }
                  onClick={backToDashboard}
                >
                  Advanced Analytics{" "}
                </span>
              }
              {currentReportName && " > " + currentReportName}
            </h5>
          </Col>
        </Row>

        {vizUrl && (
          <Row>
            <Col>
              <span
                className="text-primary cursor-pointer mx-2 my-2 d-inline-block"
                onClick={backToDashboard}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="text-primary me-1"
                />
                Back to Dashboard
              </span>
            </Col>
          </Row>
        )}

        {isTableLoading ? (
          <Card>
            <TableLoader
              headLength={tableHeaders?.length || 1}
              dataLength={data?.data?.length || 1}
            />
          </Card>
        ) : (
          <div>
            {/* TABLEAU VIEW */}
            {isGraphLoading ? (
              <div className="loaderSection mb-4 py-5 card card-body">
                <SpinnerLoader size="lg" />
              </div>
            ) : (
              vizUrl && (
                <div className="mx-auto text-center pb-4">
                  <Card>
                    <Card.Body>
                      {showTableauCustomBtn && (
                        <div
                          className={`d-flex my-1 ${
                            showViewData
                              ? "justify-content-between"
                              : "justify-content-end"
                          }`}
                        >
                          {showViewData && (
                            <button
                              className="btn btnDefault btn-info"
                              onClick={handleTableauPopUpTable}
                            >
                              View Data
                            </button>
                          )}
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="light"
                              id="dropdown-basic"
                            >
                              <FontAwesomeIcon
                                icon={faBars}
                                size="sm"
                                className="fs-14 mx-1 me-3"
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                as="button"
                                onClick={() => {
                                  downloadImage("pdf");
                                }}
                              >
                                Download PDF
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                onClick={() => downloadImage("image")}
                              >
                                Download IMAGE
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                onClick={() => downloadImage("ppt")}
                              >
                                Download PPT
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      )}

                      {!showTableauCustomBtn && (
                        <SpinnerLoader size="lg" classes=" mt-4" />
                      )}
                      <div
                        id="tableau-viz"
                        className={
                          showTableauCustomBtn ? "show-tableau" : "hide-tableau"
                        }
                      >
                        {/* <div className="bg-white">Title</div> */}
                        <span className="bg-white d-flex justify-content-start fw-bold">
                          {/* {console.log(
                                "chartBreadcrumb html=> ",
                                chartBreadcrumb
                              )} */}
                          {chartBreadcrumb?.length > 0 &&
                            chartBreadcrumb?.map((item, index) => {
                              return (
                                <span key={index}>
                                  <span
                                    role="button"
                                    href="#"
                                    className="linkText "
                                    onClick={handleBreadcrumClick}
                                  >
                                    {/*chartBreadcrumbObject[index]*/}{" "}
                                    {item
                                      .replace("Action (", "")
                                      .replace(")", "")}
                                  </span>
                                  <span
                                    // className={
                                    //   isFullScreen ? "text-white" : "text-dark"
                                    // }
                                    className="mx-1"
                                  >
                                    {index < chartBreadcrumb.length - 1
                                      ? " > "
                                      : " "}
                                  </span>
                                </span>
                              );
                            })}
                          {/* {chartBreadcrumb.length} */}
                        </span>
                        <tableau-viz
                          // id="tableau-viz"
                          src={vizUrl} // + "?:showShareOptions=no"
                          token={
                            vizUrl.includes("https://public.tableau.com")
                              ? ""
                              : token
                          }
                          // device="desktop"
                          toolbar="hidden"
                          width="100%"
                          height={chartBreadcrumb?.length > 0 ? "720" : "740"}
                          // hideTabs="true"
                          // toolbar="bottom"
                          // show-share-options="no"
                        ></tableau-viz>
                        {showTableauCustomBtn && (
                          <span
                            id="FullScreenBtn"
                            className=" d-flex justify-content-end my-1" //d-flex justify-content-between
                          >
                            <span className=" d-inline m-0 p-0">
                              {!isFullScreen && (
                                <a href="#">
                                  <FontAwesomeIcon
                                    icon={faExpand}
                                    className="text-dark"
                                    onClick={toggleFullScreen}
                                  />
                                </a>
                              )}

                              {isFullScreen && (
                                <a href="#">
                                  <FontAwesomeIcon
                                    icon={faCompress}
                                    className="text-white"
                                    onClick={toggleFullScreen}
                                  />
                                </a>
                              )}
                            </span>
                          </span>
                        )}
                      </div>

                      {/* <iframe
                        width="100%"
                        height="740"
                        src={vizUrl + "?" + `:token=${token}`}
                        // token={token}
                      ></iframe> */}
                      {/* {showTableauCustomBtn && ( */}
                      {showTableauCustomBtn && (
                        <div className="d-flex justify-content-end my-1"></div>
                      )}
                      {/* )} */}
                      {tableauError && (
                        <p className="py-5">Something went wrong</p>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              )
            )}

            <div className="pb-4">
              <Card>
                <Card.Body>
                  <Row>
                    <Col className="sm-flex-column-contaniner d-flex justify-content-end align-items-start mb-2">
                      {data?.total && (
                        <Pagination
                          className="pagination-bar mb-0 pe-0"
                          currentPage={currentPage} // ? current : currentPage}
                          totalCount={data?.total}
                          pageSize={offset}
                          onPageChange={(page) => onPageChange(page)}
                        />
                      )}
                    </Col>
                  </Row>
                  <Table
                    responsive
                    className="tableBlock table-bordered table-hover  mb-0"
                  >
                    <thead className="text-uppercase">
                      <tr className="align-middle" key={"No"}>
                        <th width="7%">No.</th>
                        {tableHeaders?.map((h, idx) => (
                          <th>{h}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {data?.total > 0 ? (
                        data?.data?.map((data, idx) => {
                          return (
                            <>
                              {data.show && (
                                <tr>
                                  <td>
                                    {idx + 1 + (currentPage - 1) * offset}
                                  </td>
                                  <td>{data.fileName}</td>
                                  <td>
                                    Click{" "}
                                    <a
                                      style={{
                                        color: "blue",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setVizUrl(
                                          data.link +
                                            "?:showShareOptions=no" +
                                            "&:hideEditButton=true" +
                                            "&:hideTabs=true"
                                        );
                                        setCurrentReportName(data.fileName);
                                      }}
                                    >
                                      here
                                    </a>{" "}
                                    to view Report
                                  </td>
                                </tr>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={9}>
                            <div className="text-center">
                              <h6>
                                <FontAwesomeIcon
                                  icon={faInfoCircle}
                                  className="text-info me-1"
                                />
                                No data found
                              </h6>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AdvancedAnalytics;
