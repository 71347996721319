import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Filter, { handleGAEvent } from "../../components/Filter/Filter";
import AxiosService from "../../utils/AxiosService";
import { setDateRange, setTableLoader } from "../../Redux/Action/filter.action";

import TableGridLayout from "../../components/TableGridLayout/TableGridLayout";
import ToggleMultiline from "../../components/graphs/D3/MultiLine/ToggleMultiline";
import ToggleStackBarChart from "../../components/graphs/D3/StackBarChart/ToggleStackBarChart";
import DoubleLayerStackBarChart from "../../components/graphs/D3/DoubleLayerStackBarChart/DoubleLayerStackBarChart";
import { SpinnerLoader } from "../../components/Loader/Loader";
import MultiBarCustomChart from "../../components/graphs/D3/MultiBarCustomChart/MultiBarCustomChart";

const InsightsByJournalCascade = () => {
  const {
    searchValue,
    dateRange,
    status,
    currentPage,
    rejectingJournal,
    acceptingJournal,
    publishers,
    accessType,
    isPageReload,
  } = useSelector((state) => state.allFilters);

  const GridData2 = {
    total: 211,
    hasSubHeader: true,
    config: [
      {
        tableDisplay: true,
        field: "s_journal",
        display: "REJECTING JOURNAL",
        dataType: "string",
        sortable: false,
        isSubHeader: false,
        subHeaderNumber: 0,
        subHeaderDisplay: [],
      },
      {
        tableDisplay: true,
        field: "2017",
        display: "2017",
        dataType: "string",
        sortable: false,
        isSubHeader: true, //missing
        subHeaderNumber: 2, // missing
        subHeaderDisplay: [
          {
            tableDisplay: true,
            field: "with_us",
            display: "WITH US",
            dataType: "string",
            sortable: false,
            parentColName: "2017",
            parentColField: "2017",
            parentRowField: "s_journal",
          },
          {
            tableDisplay: true,
            field: "elsewere",
            display: "elsewere",
            dataType: "string",
            sortable: false,
            parentColName: "2017",
            parentColField: "2017",
            parentRowField: "s_journal",
          },
        ],
      },
      {
        tableDisplay: true,
        field: "2018",
        display: "2018",
        dataType: "string",
        sortable: false,
        isSubHeader: true,
        subHeaderNumber: 2,
        subHeaderDisplay: [
          {
            tableDisplay: true,
            field: "with_us",
            display: "WITH US",
            dataType: "string",
            sortable: false,
            parentColName: "2018",
            parentColField: "2018",
            parentRowField: "s_journal",
          },
          {
            tableDisplay: true,
            field: "elsewere",
            display: "elsewere",
            dataType: "string",
            sortable: false,
            parentColName: "2018",
            parentColField: "2018",
            parentRowField: "s_journal",
          },
        ],
      },
      {
        tableDisplay: true,
        field: "2019",
        display: "2019",
        dataType: "string",
        sortable: false,
        isSubHeader: true,
        subHeaderNumber: 2,
        subHeaderDisplay: [
          {
            tableDisplay: true,
            field: "with_us",
            display: "WITH US",
            dataType: "string",
            sortable: false,
            parentColName: "2019",
            parentColField: "2019",
            parentRowField: "s_journal",
          },
          {
            tableDisplay: true,
            field: "elsewere",
            display: "elsewere",
            dataType: "string",
            sortable: false,
            parentColName: "2019",
            parentColField: "2019",
            parentRowField: "s_journal",
          },
        ],
      },
      {
        tableDisplay: true,
        field: "2020",
        display: "2020",
        dataType: "string",
        sortable: false,
        isSubHeader: true,
        subHeaderNumber: 2,
        subHeaderDisplay: [
          {
            tableDisplay: true,
            field: "with_us",
            display: "WITH US",
            dataType: "string",
            sortable: false,
            parentColName: "2020",
            parentColField: "2020",
            parentRowField: "s_journal",
          },
          {
            tableDisplay: true,
            field: "elsewere",
            display: "elsewere",
            dataType: "string",
            sortable: false,
            parentColName: "2020",
            parentColField: "2020",
            parentRowField: "s_journal",
          },
        ],
      },
      {
        tableDisplay: true,
        field: "2021",
        display: "2021",
        dataType: "string",
        sortable: false,
        isSubHeader: true,
        subHeaderNumber: 2,
        subHeaderDisplay: [
          {
            tableDisplay: true,
            field: "with_us",
            display: "WITH US",
            dataType: "string",
            sortable: false,
            parentColName: "2021",
            parentColField: "2021",
            parentRowField: "s_journal",
          },
          {
            tableDisplay: true,
            field: "elsewere",
            display: "elsewere",
            dataType: "string",
            sortable: false,
            parentColName: "2021",
            parentColField: "2021",
            parentRowField: "s_journal",
          },
        ],
      },
      {
        tableDisplay: true,
        field: "2022",
        display: "2022",
        dataType: "string",
        sortable: false,
        isSubHeader: true,
        subHeaderNumber: 2,
        subHeaderDisplay: [
          {
            tableDisplay: true,
            field: "with_us",
            display: "WITH US",
            dataType: "string",
            sortable: false,
            parentColName: "2022",
            parentColField: "2022",
            parentRowField: "s_journal",
          },
          {
            tableDisplay: true,
            field: "elsewere",
            display: "elsewere",
            dataType: "string",
            sortable: false,
            parentColName: "2022",
            parentColField: "2022",
            parentRowField: "s_journal",
          },
        ],
      },
      {
        tableDisplay: true,
        field: "2023",
        display: "2023",
        dataType: "string",
        sortable: false,
        isSubHeader: true,
        subHeaderNumber: 3,
        subHeaderDisplay: [
          {
            tableDisplay: true,
            field: "with_us",
            display: "WITH US",
            dataType: "url",
            sortable: false,
            parentColName: "2023",
            parentColField: "2023",
            parentRowField: "s_journal",
          },
          {
            tableDisplay: true,
            field: "elsewere",
            display: "WLSEWERE",
            dataType: "url",
            sortable: false,
            parentColName: "2023",
            parentColField: "2023",
            parentRowField: "s_journal",
          },
        ],
      },
    ],
    data: [
      {
        2017: { with_us: 0, elsewere: 0 },
        2018: { with_us: 0, elsewere: 0 },
        2019: { with_us: 0, elsewere: 0 },
        2020: { with_us: 0, elsewere: 0 },
        2021: { with_us: 2776, elsewere: 3535 },
        2022: { with_us: 4786, elsewere: 6892 },
        2023: { with_us: 8523, elsewere: 8348 },
        s_journal: "IEEE Access",
      },
      {
        2017: { with_us: 0, elsewere: 0 },
        2018: { with_us: 0, elsewere: 0 },
        2019: { with_us: 0, elsewere: 0 },
        2020: { with_us: 0, elsewere: 0 },
        2021: { with_us: 246, elsewere: 511 },
        2022: { with_us: 916, elsewere: 813 },
        2023: { with_us: 2312, elsewere: 2171 },
        s_journal: "IEEE Sensors Journal",
      },
      {
        2017: { with_us: 0, elsewere: 0 },
        2018: { with_us: 0, elsewere: 0 },
        2019: { with_us: 0, elsewere: 0 },
        2020: { with_us: 0, elsewere: 0 },
        2021: { with_us: 278, elsewere: 560 },
        2022: { with_us: 1279, elsewere: 583 },
        2023: { with_us: 1193, elsewere: 2220 },
        s_journal: "Transactions on Industrial Electronics",
      },
      {
        2017: { with_us: 0, elsewere: 0 },
        2018: { with_us: 0, elsewere: 0 },
        2019: { with_us: 0, elsewere: 0 },
        2020: { with_us: 0, elsewere: 0 },
        2021: { with_us: 16, elsewere: 12 },
        2022: { with_us: 456, elsewere: 388 },
        2023: { with_us: 2406, elsewere: 1611 },
        s_journal: "IEEE Transactions on Intelligent Vehicles",
      },
      {
        2017: { with_us: 0, elsewere: 0 },
        2018: { with_us: 0, elsewere: 0 },
        2019: { with_us: 0, elsewere: 0 },
        2020: { with_us: 8, elsewere: 7 },
        2021: { with_us: 336, elsewere: 230 },
        2022: { with_us: 825, elsewere: 621 },
        2023: { with_us: 1851, elsewere: 1225 },
        s_journal: "IEEE Transactions on Systems, Man and Cybernetics: Systems",
      },
      {
        2017: { with_us: 0, elsewere: 0 },
        2018: { with_us: 0, elsewere: 0 },
        2019: { with_us: 0, elsewere: 0 },
        2020: { with_us: 0, elsewere: 0 },
        2021: { with_us: 212, elsewere: 368 },
        2022: { with_us: 651, elsewere: 830 },
        2023: { with_us: 1496, elsewere: 2170 },
        s_journal:
          "IEEE Transactions on Circuits and Systems for Video Technology",
      },
      {
        2017: { with_us: 0, elsewere: 0 },
        2018: { with_us: 0, elsewere: 0 },
        2019: { with_us: 0, elsewere: 0 },
        2020: { with_us: 1, elsewere: 5 },
        2021: { with_us: 391, elsewere: 224 },
        2022: { with_us: 1332, elsewere: 1136 },
        2023: { with_us: 2065, elsewere: 2812 },
        s_journal: "IEEE Transactions on Neural Networks and Learning Systems",
      },
      {
        2017: { with_us: 0, elsewere: 0 },
        2018: { with_us: 0, elsewere: 0 },
        2019: { with_us: 0, elsewere: 0 },
        2020: { with_us: 1, elsewere: 1 },
        2021: { with_us: 287, elsewere: 389 },
        2022: { with_us: 1108, elsewere: 400 },
        2023: { with_us: 2045, elsewere: 1285 },
        s_journal: "IEEE Journal of Biomedical and Health Informatics",
      },
      {
        2017: { with_us: 0, elsewere: 0 },
        2018: { with_us: 0, elsewere: 0 },
        2019: { with_us: 0, elsewere: 0 },
        2020: { with_us: 0, elsewere: 1 },
        2021: { with_us: 225, elsewere: 417 },
        2022: { with_us: 764, elsewere: 760 },
        2023: { with_us: 1942, elsewere: 1471 },
        s_journal: "IEEE Transactions on Vehicular Technology",
      },
      {
        2017: { with_us: 0, elsewere: 0 },
        2018: { with_us: 0, elsewere: 0 },
        2019: { with_us: 0, elsewere: 0 },
        2020: { with_us: 0, elsewere: 0 },
        2021: { with_us: 289, elsewere: 393 },
        2022: { with_us: 573, elsewere: 878 },
        2023: { with_us: 2224, elsewere: 820 },
        s_journal: "IEEE Transactions on Cybernetics",
      },
    ],
  };
  // const demo = { total: 94, hasSubHeader: true, config: [] };

  const GridData = {
    total: 94,
    hasSubHeader: true,
    config: [
      {
        tableDisplay: true,
        field: "s_journal",
        display: "REJECTING JOURNAL",
        dataType: "string",
        sortable: false,
        isSubHeader: false,
        subHeaderNumber: 0,
        subHeaderDisplay: [],
      },
      {
        tableDisplay: true,
        field: "2022",
        display: "2022",
        dataType: "number",
        isSubHeader: true, //missing
        subHeaderNumber: 2, // missing
        sortable: false,
        subHeaderDisplay: [
          {
            tableDisplay: true,
            field: "with_us",
            display: "With Us",
            dataType: "url",
            sortable: false,
            parentColName: "2022",
            parentColField: "2022",
            parentRowField: "s_journal",
          },
          {
            tableDisplay: true,
            field: "elsewhere",
            display: "Elsewhere",
            dataType: "url",
            sortable: false,
            parentColName: "2022",
            parentColField: "2022",
            parentRowField: "s_journal",
          },
        ],
      },
      {
        tableDisplay: true,
        field: "2023",
        display: "2023",
        dataType: "number",
        isSubHeader: true, //missing
        subHeaderNumber: 2, // missing
        sortable: false,
        subHeaderDisplay: [
          {
            tableDisplay: true,
            field: "with_us",
            display: "With Us",
            dataType: "url",
            sortable: false,
            parentColName: "2023",
            parentColField: "2023",
            parentRowField: "s_journal",
          },
          {
            tableDisplay: true,
            field: "elsewhere",
            display: "Elsewhere",
            dataType: "url",
            sortable: false,
            parentColName: "2023",
            parentColField: "2023",
            parentRowField: "s_journal",
          },
        ],
      },
    ],
    data: [
      {
        2022: {
          with_us: 13,
          elsewhere: 443,
        },
        2023: {
          with_us: 2,
          elsewhere: 192,
        },
        s_journal: "Oncology Research and Treatment",
      },
      {
        2022: {
          with_us: 6,
          elsewhere: 435,
        },
        2023: {
          with_us: 6,
          elsewhere: 192,
        },
        s_journal: "Complementary Medicine Research",
      },
      {
        2022: {
          with_us: 9,
          elsewhere: 429,
        },
        2023: {
          with_us: 2,
          elsewhere: 162,
        },
        s_journal: "Respiration",
      },
      {
        2022: {
          with_us: 8,
          elsewhere: 395,
        },
        2023: {
          with_us: 2,
          elsewhere: 188,
        },
        s_journal: "Cardiology",
      },
      {
        2022: {
          with_us: 11,
          elsewhere: 394,
        },
        2023: {
          with_us: 1,
          elsewhere: 163,
        },
        s_journal: "Urologia Internationalis",
      },
      {
        2022: {
          with_us: 5,
          elsewhere: 320,
        },
        2023: {
          with_us: 3,
          elsewhere: 235,
        },
        s_journal: "Dermatology",
      },
      {
        2022: {
          with_us: 11,
          elsewhere: 364,
        },
        2023: {
          with_us: 7,
          elsewhere: 172,
        },
        s_journal: "Cerebrovascular Diseases",
      },
      {
        2022: {
          with_us: 4,
          elsewhere: 377,
        },
        2023: {
          with_us: 1,
          elsewhere: 164,
        },
        s_journal: "Liver Cancer",
      },
      {
        2022: {
          with_us: 1,
          elsewhere: 399,
        },
        2023: {
          with_us: 1,
          elsewhere: 138,
        },
        s_journal: "Gynecologic and Obstetric Investigation",
      },
      {
        2022: {
          with_us: 30,
          elsewhere: 311,
        },
        2023: {
          with_us: 12,
          elsewhere: 177,
        },
        s_journal: "American Journal of Nephrology",
      },
    ],
  };

  const dispatch = useDispatch();
  const axiosService = new AxiosService();
  const [loading, setLoading] = useState({
    isApplyFilter: false,
    isClearFilter: false,
    isGo: false,
  });
  const [chartLoader, setChartLoader] = useState(false);
  const [graphLoader, setGraphLoader] = useState(false);
  const [reqBody, setReqBody] = useState({});

  const [chartData, setChartData] = useState([]);
  const [fullChartData, setFullChartData] = useState({});

  const [activeBtn, setActiveBtn] = useState("Rejection");

  const [gridData, setGridData] = useState({});
  const [sort, setSort] = useState({
    field: "totalPublication",
    order: "desc",
  });
  const [start, setStart] = useState(0);
  const offset = 10;

  const getAllRecords = async () => {
    let startDate = "";
    let endDate = "";
    if (
      dateRange.rejectionStartDate.length > 10 ||
      dateRange.rejectionEndDate.length > 10
    ) {
      startDate = dateRange.rejectionStartDate.slice(0, 10);
      endDate = dateRange.rejectionEndDate.slice(0, 10);
    } else {
      startDate = dateRange.rejectionStartDate;
      endDate = dateRange.rejectionEndDate;
    }
    dispatch(setTableLoader(true));
    setGraphLoader(true);
    const start = (currentPage - 1) * offset;
    setStart(start);
    const reqBody = {
      vendor: "",
      start_date: startDate,
      end_date: endDate,
      status: status,
      searchTerm: searchValue,
      filter: {
        s_journal: rejectingJournal,
        p_journal: acceptingJournal,
        p_publisher: publishers,
      },
      // sort: {
      //   field: sort.field,
      //   order: sort.order,
      // },
      // card_type: activeBtn == "Rejection" ? "rejected" : "published",
    };
    if (accessType) {
      reqBody["filter"].oa = true;
    }

    setChartLoader(true);
    axiosService
      .getRejectingJournalCascadeChart(start, offset, reqBody)
      .then((response) => {
        // console.log("response.data => ", response.data.publishedTrend);
        if (response?.data) {
          setGraphLoader(false);
          setLoading({
            ...loading,
            isApplyFilter: false,
            isClearFilter: false,
            isGo: false,
          });
          setFullChartData(response?.data.publishedTrend);
          setChartLoader(false);

          // if (activeBtn === "Rejection") {
          //   setChartData(response.data && response.data.rejectionTrend);
          // } else if (activeBtn === "Published") {
          setChartData(response.data && response.data.publishedTrend);
          // }
        }
      })
      .catch((error) => {
        setGraphLoader(false);
        setChartLoader(false);
        setChartData({});
      });

    axiosService
      .getRejectingJournalCascadeGrid(start, offset, reqBody)
      .then((response) => {
        if (response?.data) {
          dispatch(setTableLoader(false));
          setLoading({
            ...loading,
            isApplyFilter: false,
            isClearFilter: false,
            isGo: false,
          });
          setGridData(response?.data);
        }
      })
      .catch((error) => {
        dispatch(setTableLoader(false));
        setGridData({});
      });
    setReqBody(reqBody);
    handleGAEvent(loading, reqBody);
  };

  useEffect(() => {
    if (dateRange.rejectionStartDate) {
      getAllRecords();
    }
  }, [currentPage, sort, isPageReload, activeBtn]);

  useEffect(() => {
    if (
      (loading.isApplyFilter || loading.isGo) &&
      dateRange.rejectionStartDate
    ) {
      setSort({
        field: "totalPublication",
        order: "desc",
      });
    }
  }, [loading.isApplyFilter, loading.isGo]);

  useEffect(() => {
    if (activeBtn === "Published") {
      setActiveBtn("Rejection");
    }

    setSort({
      field: "totalPublication",
      order: "desc",
    });
  }, [loading.isClearFilter]);

  const getRejectionChart = () => {
    setGraphLoader(true);

    setChartData(fullChartData.rejectionTrend);

    setTimeout(() => {
      setGraphLoader(false);
    }, 500);
  };

  const getPublishedChart = () => {
    setGraphLoader(true);

    setChartData(fullChartData.publishedTrend);

    setTimeout(() => {
      setGraphLoader(false);
    }, 500);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <Filter
        loading={loading}
        setLoading={setLoading}
        getAllRecords={getAllRecords}
      />

      {/* <ToggleStackBarChart
        data={chartData}
        activeBtn={activeBtn}
        setActiveBtn={setActiveBtn}
        getRejectionChart={getRejectionChart}
        getPublishedChart={getPublishedChart}
        loader={graphLoader}
        // title="Journal Cascade"
        loading={loading}
      /> */}

      {chartLoader ? (
        <div className="loaderSection mt-4 card card-body">
          <SpinnerLoader size="lg" />
        </div>
      ) : (
        <div>
          {/* {Object.keys(chartData).length && (
          <DoubleLayerStackBarChart
            data={chartData}
            journalType={"journalCascade"}
          />
        )} */}
          {/* <MultiLine data={chartData} journalType={"journalCascade"} /> */}
          <MultiBarCustomChart
            data={chartData}
            journalType={"journalCascade"}
          />
        </div>
      )}

      {Object.keys(gridData).length ? (
        <TableGridLayout
          data={gridData}
          start={start}
          setStart={setStart}
          offset={offset}
          gridTitle={"Journals by Publication Count"}
          getAllRecords={getAllRecords}
          sort={sort}
          setSort={setSort}
          reqBody={reqBody}
          gridType="rejectingJournalCascadeGrid"
        />
      ) : null}
    </div>
  );
};

export default InsightsByJournalCascade;
